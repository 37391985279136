import { memo, FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Header } from '@/components/common/Header';
import { ExitModal } from '@/components/common/ExitModal';

interface Props {
  children: ReactNode,
  sessionId: string,
  isLiveness: boolean
};

export const Layout: FC<Props> = memo(({ children, sessionId, isLiveness }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <SContainer>
      <SBox>
        {!isLiveness && <Header setIsModalOpen={setIsModalOpen} />}
        {children}
      </SBox>
      <ExitModal sessionId={sessionId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </SContainer>
  );
});

const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  gap: 1rem;
`;

const SBox = styled.div`
  width: 100%;
  max-width: 640px;
  height: 100vh;
  margin: 0 auto;
  background-color: white;
`;
