import { Dispatch, FC, SetStateAction } from 'react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';

import { AWS_REGION } from '@/config/constants';
import { japaneseLivenessDisplayText } from './dictionary/japaneseLivenessDisplayText';
import './styles.css';

interface Props {
  sessionId: string,
  setCurrentStep: Dispatch<SetStateAction<string>>,
  setErrorMessage: Dispatch<SetStateAction<string>>
}

export const StartFaceLiveness: FC<Props> = ({ sessionId, setCurrentStep, setErrorMessage }) => {
  const handleAnalysisComplete = async () => setCurrentStep('ProcessEkyc');
  const handleAnalysisError = () => {
    setErrorMessage('本人確認中に問題が発生しました。時間をおいて再度お試しください。');
    setCurrentStep('ErrorPage');
  };
  const handleUserCancel = () => {
    setErrorMessage('本人確認が中断されました。');
    setCurrentStep('ErrorPage');
  };

  return (
    <div className="custom-face-liveness-container">
      <FaceLivenessDetector
        sessionId={sessionId}
        region={AWS_REGION}
        displayText={japaneseLivenessDisplayText}
        onAnalysisComplete={handleAnalysisComplete}
        onError={handleAnalysisError}
        onUserCancel={handleUserCancel}
      />
    </div>
  );
};
