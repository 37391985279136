import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';

import { idOptions } from '@/utils/idOptions';
import { PageGuide } from '@/components/common/PageGuide';
import { PrimaryButton } from '@/components/common/button/PrimaryButton';

interface Props {
  idType: number,
  setIdType: Dispatch<SetStateAction<number>>,
  setCurrentStep: Dispatch<SetStateAction<string>>,
  setErrorMessage: Dispatch<SetStateAction<string>>
}

export const SelectIdType: FC<Props> = ({ idType, setIdType, setCurrentStep, setErrorMessage }) => {
  const [isCameraAccessGranted, setIsCameraAccessGranted] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  // カメラのアクセス許可チェック
  useEffect(() => {
    const checkCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(track => track.stop());
      } catch {
        setIsCameraAccessGranted(false);
        setErrorMessage(`カメラへのアクセスが拒否されました。アプリの設定からカメラへのアクセスを許可いただき、再度お試しください。`);
      }
    };

    checkCameraAccess();
  }, []);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setShowWarning(value === 0);
    setIdType(value);
  };

  const onClickSubmit = () => {
    if (idType === 0) {
      setShowWarning(true);
      return;
    }
    setCurrentStep(isCameraAccessGranted ? 'UploadIdImage' : 'ErrorPage');
  };

  return (
    <>
      <PageGuide>
        撮影したい公的身分証を用意してください。
      </PageGuide>
      <SBody>
        <SSelect value={idType} onChange={handleSelectChange} >
          {idOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SSelect>
        {showWarning && (
          <SWarningText>
            公的身分証を選択してください。
          </SWarningText>
        )}
        <PrimaryButton onClick={onClickSubmit}>次へ</PrimaryButton>
      </SBody>
    </>
  );
};

const SBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const SSelect = styled.select`
  background-color: white;
  color: #595959;
  text-align: center;
  margin: 30px 0;
  width: 60%;
  height: 37px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const SWarningText = styled.p`
  color: #e53e3e;
  margin-bottom: 1rem;
  font-size: 1rem;
`;
