import { FC, memo, ReactNode } from "react";
import styled from 'styled-components';

interface Props {
  children: ReactNode,
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick: () => void
};

export const CommonButton: FC<Props> = memo(({ children, className, disabled = false, loading = false, onClick }) => {
  return (
    <SButton disabled={disabled || loading} onClick={onClick} className={className} >
      {loading ? 'Loading...' : children}
    </SButton>
  );
});

const SButton = styled.button`
  background-color: rgb(0 190 126);
  color: white;
  padding: 0 30px;
  height: 44px;
  width: 100%;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
