export default {
  errorLabelText: 'Error',
  timeoutHeaderText: 'Time out',
  timeoutMessageText:
    "Face didn't fit inside oval in time limit. Try again and completely fill the oval with face in it.",
  faceDistanceHeaderText: 'Forward movement detected',
  faceDistanceMessageText: 'Avoid moving closer when connecting.',
  multipleFacesHeaderText: 'Multiple faces detected',
  multipleFacesMessageText:
    'Ensure only one face is present in front of the camera when connecting.',
  clientHeaderText: 'Client error',
  clientMessageText: 'Check failed due to client issue',
  serverHeaderText: 'Server issue',
  serverMessageText: 'Cannot complete check due to server issue',
  landscapeHeaderText: 'Landscape orientation not supported',
  landscapeMessageText:
    'Rotate your device to portrait (vertical) orientation.',
  portraitMessageText:
    'Ensure your device remains in portrait (vertical) orientation for the check’s duration.',
  tryAgainText: 'Try again',
};
