import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  children: string
}

export const Loader: FC<Props> = ({children}) => {
  return (
    <SLoadingContainer>
      <SLoadingSpinner />
      <SLoadingText>{children}</SLoadingText>
    </SLoadingContainer>
  );
};

const SLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  gap: 8px;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SLoadingSpinner = styled.div`
  border: 4px solid rgba(0, 128, 128, 0.2);
  border-top: 4px solid #319795;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 0.6s linear infinite;
`

const SLoadingText = styled.p`
  color: #319795;
  font-size: 1rem;
  text-align: center;
`
