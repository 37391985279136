// AWS Config
export const AWS_COGNITO_IDENTITY_POOL_ID = import.meta.env.VITE_IDENTITYPOOL_ID || '';
export const AWS_COGNITO_USER_POOL_ID = import.meta.env.VITE_USERPOOL_ID || '';
export const AWS_COGNITO_USER_POOL_CLIENT_ID = import.meta.env.VITE_WEBCLIENT_ID || '';
export const AWS_REGION = import.meta.env.VITE_REGION || '';

// API Endpoints
const API_BASE_URL = import.meta.env.VITE_ENV_API_URL || '';
export const FACE_LIVENESS_SESSION_ENDPOINT = `${API_BASE_URL}create-session`;
export const S3_PRESIGNED_URL_ENDPOINT = `${API_BASE_URL}uploadsignedurl`;
export const PROCESS_REKOGNITION_ENDPOINT = `${API_BASE_URL}process-recognition`;
