const getUserAgent = () => window.navigator.userAgent.toLowerCase()

export const isAndroid = () => {
  return getUserAgent().indexOf('android') > -1
}

export const isIos = () => {
  const ua = getUserAgent()
  return ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1
}

export const isMobile = () => {
  return isAndroid() || isIos()
}

export const isPc = () => {
  return !isMobile()
}
