import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
} from 'react';

import { Loader } from '@/components/pages/Loader';
import { PageGuide } from '@/components/common/PageGuide';
import { RekognitionEkycResult } from '@/utils/rekognitionEkycResult';
import { PROCESS_REKOGNITION_ENDPOINT } from '@/config/constants';

interface Props {
  sessionId: string,
  setRekognitionEkycResult: Dispatch<SetStateAction<RekognitionEkycResult>>,
  setCurrentStep: Dispatch<SetStateAction<string>>,
  setErrorMessage: Dispatch<SetStateAction<string>>
}

export const ProcessEkyc: FC<Props>  = ({ sessionId, setRekognitionEkycResult, setCurrentStep, setErrorMessage }) => {
  useEffect(() => {
    const requestEkyc = async () => {
      try {
        const response = await fetch(PROCESS_REKOGNITION_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId: sessionId }),
        });
        if (!response.ok) throw new Error('本人確認中にエラーが発生しました。');

        setRekognitionEkycResult({
          sessionId: sessionId,
          status: 'completed'
        });
        setCurrentStep('CompleteEkyc');
      } catch(error) {
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          setErrorMessage('サーバーがタイムアウトしました。もう一度お試しください。');
        } else {
          setErrorMessage(error.message || '不明なエラーが発生しました。');
        }
        setCurrentStep('ErrorPage');
      }
    };

    requestEkyc();
  }, []);

  return (
    <>
      <PageGuide>
        本人確認書類との照合を行なっています。しばらくお待ちください。
      </PageGuide>
      <Loader>
        処理中です・・・
      </Loader>
    </>
  );
};
