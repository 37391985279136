import { FC } from 'react';
import styled from 'styled-components';

import { PageGuide } from '@/components/common/PageGuide';
import { PrimaryButton } from '@/components/common/button/PrimaryButton';
import { RekognitionEkycResult, sendRekognitionEkycResult } from '@/utils/rekognitionEkycResult';

interface Props {
  rekognitionEkycResult: RekognitionEkycResult
}

export const CompleteEkyc: FC<Props> = ({rekognitionEkycResult}) => {
  const onClickCompleteEkyc = () => sendRekognitionEkycResult(rekognitionEkycResult);

  return (
    <>
      <PageGuide>
        本人確認書類の提出が完了しました。
      </PageGuide>
      <SBody>
        <SMessage>アプリに戻って結果を確認してください。</SMessage>
        <PrimaryButton onClick={onClickCompleteEkyc}>
          OK
        </PrimaryButton>
      </SBody>
    </>
  );
};

const SBody = styled.div`
  padding: 20px;
`;

const SMessage = styled.p`
  text-align: center;
  margin: 20px 0 80px 0;
`;
