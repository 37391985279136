import { FC } from 'react';
import styled from 'styled-components';

import { SecondaryButton } from '@/components/common/button/SecondaryButton';
import { sendRekognitionEkycResult } from '@/utils/rekognitionEkycResult';

interface Props {
  sessionId: string,
  errorMessage: string
}

export const ErrorPage: FC<Props> = ({ sessionId, errorMessage }) => {
  const onClickExit = () => sendRekognitionEkycResult({ sessionId: sessionId, status: 'error' });

  return (
    <SContainer>
      <SMessage>{errorMessage}</SMessage>
      <SButtonContainer>
        <SecondaryButton onClick={onClickExit} >アプリに戻る</SecondaryButton>
      </SButtonContainer>
    </SContainer>
  );
};

const SContainer = styled.div`
  padding: 20px;
`;

const SMessage = styled.p`
  font-size: 1.25rem;
  color: #4a4a4a;
  margin: 20px 0 40px 0;
  text-align: center;
`;

const SButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
