interface IdOption {
  value: number,
  label: string
};

export const idOptions: IdOption[] = [
  { value: 0, label: '公的身分証を選択' },
  { value: 1, label: '運転免許証' },
  { value: 2, label: '日本国パスポート' },
  { value: 3, label: 'マイナンバーカード' },
  { value: 4, label: '在留カード' }
];
