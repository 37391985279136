import { memo, FC, Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';

import { PrimaryButton } from '@/components/common/button/PrimaryButton';
import { SecondaryButton } from '@/components/common/button/SecondaryButton';
import { sendRekognitionEkycResult } from '@/utils/rekognitionEkycResult';

interface Props {
  sessionId: string,
  isModalOpen: boolean,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

export const ExitModal: FC<Props> = memo(({ sessionId, isModalOpen, setIsModalOpen }) => {
  const closeModal = () => setIsModalOpen(false);
  const abortEkyc = () => sendRekognitionEkycResult({ sessionId: sessionId, status: 'aborted' });

  if (!isModalOpen) return null;

  return (
    <SModalOverlay>
      <SModalContainer>
        <SModalHeader>
          <h2 style={{ textAlign: 'center' }}>終了しますか？</h2>
        </SModalHeader>
        <SModalBody>
          <SCheckIconContainer>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </SCheckIconContainer>
        </SModalBody>
        <SButtonContainer>
          <SPrimaryButton onClick={abortEkyc}>
            終了する
          </SPrimaryButton>
          <SecondaryButton onClick={closeModal}>
            戻る
          </SecondaryButton>
        </SButtonContainer>
      </SModalContainer>
    </SModalOverlay>
  );
});

const SModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const SModalContainer = styled.div`
  width: 80%;
  max-width: 335px;
  height: 50vh;
  max-height: 364px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const SModalHeader = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const SModalBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

const SCheckIconContainer = styled.div`
  font-size: 64px;
  margin-bottom: 20px;
`;

const SButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SPrimaryButton = styled(PrimaryButton)`
  margin: 10px 0;
`;
