import { FC, memo, ReactNode } from "react";
import styled from 'styled-components';

interface Props {
  children: ReactNode
};

export const PageGuide: FC<Props> = memo(({children}) => {
  return (
    <SPageGuide>
      {children}
    </SPageGuide>
  );
});

const SPageGuide = styled.div`
  text-align: center;
  font-size: 1rem;
  background-color: #595959;
  color: white;
  padding: 10px;
`;
