import { Dispatch, FC, SetStateAction } from "react";
import styled from 'styled-components';

import { idOptions } from '@/utils/idOptions';
import { PageGuide } from '@/components/common/PageGuide';
import { PrimaryButton } from '@/components/common/button/PrimaryButton';
import { SecondaryButton } from '@/components/common/button/SecondaryButton';

interface Props {
  idType: number,
  idImage: string,
  setIdImage: Dispatch<SetStateAction<string | null>>,
  setCurrentStep: Dispatch<SetStateAction<string>>
}

export const ReferenceIdImage: FC<Props> = ({ idType, idImage, setIdImage, setCurrentStep }) => {
  const idTypeLabel = idOptions.find(option => option.value === idType)?.label;
  const onClickSubmit = () => setCurrentStep('FaceLiveness');
  const onClickTryAgain = () => {
    setIdImage(null);
    setCurrentStep('UploadIdImage');
  };

  return (
    <>
      <PageGuide>
        撮影した写真を確認してください。
      </PageGuide>
      <SLabel>選択した身分証 : {idTypeLabel}</SLabel>
      <SListContainer>
        <SListItem>選択した身分証が撮影されていますか？</SListItem>
        <SListItem>身分証の顔画像は鮮明に写っていますか？</SListItem>
        <SListItem>身分証の文字を読み取ることができますか？</SListItem>
      </SListContainer>
      <SImageContainer>
        <SImage src={idImage} alt="Screenshot" />
      </SImageContainer>
      <SButtonGroup>
        <SSecondaryButton onClick={onClickTryAgain}>
          やり直す
        </SSecondaryButton>
        <SPrimaryButton onClick={onClickSubmit}>
          提出する
        </SPrimaryButton>
      </SButtonGroup>
    </>
  );
};

const SLabel = styled.p`
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0;
  padding: 0 20px;
`;

const SListContainer = styled.ul`
  list-style: inside;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  padding: 0 20px;
`;

const SListItem = styled.li`
  margin: 0.5rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:before {
    content: "•";
    font-weight: bold;
  }
`;

const SImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SImage = styled.img`
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 80%;
    height: 80%;
  }
`;

const SButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 30px 20px 40px 20px;
`;

const SPrimaryButton = styled(PrimaryButton)`
  flex: 3;
`;

const SSecondaryButton = styled(SecondaryButton)`
  flex: 2;
`;
