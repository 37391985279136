import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Webcam from "react-webcam";
import styled from 'styled-components';

import { isMobile } from '@/utils/userAgent';
import { PageGuide } from '@/components/common/PageGuide';
import { PrimaryButton } from '@/components/common/button/PrimaryButton';
import { S3_PRESIGNED_URL_ENDPOINT } from '@/config/constants';

interface Props {
  sessionId: string,
  idType: number,
  setIdImage: Dispatch<SetStateAction<string | null>>,
  setCurrentStep: Dispatch<SetStateAction<string>>,
  setErrorMessage: Dispatch<SetStateAction<string>>
}

export const UploadIdImage: FC<Props> = ({ sessionId, idType, setIdImage, setCurrentStep, setErrorMessage }) => {
  // ==========================================================
  // リファレンスとステート管理
  // ==========================================================
  const webcamRef = useRef<Webcam | null>(null);
  const [presignedURL, setPresignedURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // ==========================================================
  // デバイスに応じたカメラ設定
  // ==========================================================
  const videoConstraints = useMemo(() => {
    return isMobile() ?
      { facingMode: "environment", width: { exact: 576 }, height: { exact: 1024 }, aspectRatio: 576 / 1024 } :
      { facingMode: "user", width: { exact: 1024 }, height: { exact: 576 }, aspectRatio: 1024 / 576 };
  }, []);

  // ==========================================================
  // 署名付きURLの取得
  // ==========================================================
  useEffect(() => {
    const getPresignedUrl = async () => {
      try {
        const response = await fetch(
          `${S3_PRESIGNED_URL_ENDPOINT}?${new URLSearchParams({ key: sessionId, type: String(idType) })}`
        );
        if (!response.ok) throw new Error('サーバーとの通信に失敗しました。');

        const data = await response.json();
        setPresignedURL(data.body)
      } catch (error) {
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          setErrorMessage('サーバーがタイムアウトしました。もう一度お試しください。');
        } else {
          setErrorMessage(error.message || '不明なエラーが発生しました。');
        }
        setCurrentStep('ErrorPage');
      }
    };

    getPresignedUrl();
  }, [sessionId]);

  // ==========================================================
  // 画像をS3にアップロード
  // ==========================================================
  const handleUploadImagetoS3 = async (imageSrc: string, presignedURL: string) => {
    const imageFetchResponse = await fetch(imageSrc);
    if (!imageFetchResponse.ok) throw new Error('撮影した写真の取得に失敗しました。');

    const imageBlob = await imageFetchResponse.blob();

    const uploadResult = await fetch(presignedURL, {
      method: 'PUT',
      body: imageBlob,
      headers: {
        'Content-Type': 'image/jpeg',
      }
    });

    if (!uploadResult.ok) throw new Error('画像のアップロードに失敗しました。');
  };

  const capture = useCallback(
    async (presignedURL: string) => {
      setIsLoading(true)
      try {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (!imageSrc) throw new Error('写真の撮影に失敗しました。アプリの設定からカメラへのアクセスを許可いただき、再度お試しください。');

        await handleUploadImagetoS3(imageSrc, presignedURL);

        setIdImage(imageSrc);
        setCurrentStep('ReferenceIdImage');
      } catch (error) {
        setErrorMessage(error.message);
        setCurrentStep('ErrorPage');
      } finally {
        setIsLoading(false);
      }
    }, [webcamRef]
  );

  // ==========================================================
  // レンダリング
  // ==========================================================
  return (
    <>
      <PageGuide>
        「撮影」ボタンをクリックして、顔写真付きの本人確認書類の写真を撮影します。
      </PageGuide>
      <SVideoContainer>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          forceScreenshotSourceSize={true}
          style={{
            width: '100%',
            objectFit: 'contain',
          }}
        />
      </SVideoContainer>
      <SButtonGroup>
        <PrimaryButton disabled={isLoading} onClick={() => capture(presignedURL)}>
          撮影
        </PrimaryButton>
      </SButtonGroup>
    </>
  );
};

const SVideoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SButtonGroup = styled.div`
  padding: 30px 20px 40px 20px;
`;
