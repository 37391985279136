// 既存のCognitoを使用する。
// https://docs.amplify.aws/react/build-a-backend/auth/use-existing-cognito-resources/
import { ResourcesConfig } from "aws-amplify";

import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_USER_POOL_CLIENT_ID
} from '@/config/constants';

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolId: AWS_COGNITO_USER_POOL_ID,
      userPoolClientId: AWS_COGNITO_USER_POOL_CLIENT_ID,
      allowGuestAccess: true,
    },
  },
};
