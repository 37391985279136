import { FC, useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { amplifyConfig } from '@/config/amplifyConfig';
import { Layout } from '@/components/layouts/Layout';
import { Loader } from '@/components/pages/Loader';
import { SelectIdType } from '@/components/pages/SelectIdType';
import { UploadIdImage } from '@/components/pages/idImage/UploadIdImage';
import { ReferenceIdImage } from '@/components/pages/idImage/ReferenceIdImage';
import { StartFaceLiveness } from '@/components/pages/faceLiveness/StartFaceLiveness';
import { ProcessEkyc} from '@/components/pages/ProcessEkyc';
import { CompleteEkyc} from '@/components/pages/CompleteEkyc';
import { ErrorPage } from '@/components/pages/ErrorPage';
import { RekognitionEkycResult } from '@/utils/rekognitionEkycResult';
import { FACE_LIVENESS_SESSION_ENDPOINT } from '@/config/constants';
import "./App.css";

Amplify.configure(amplifyConfig);

// Reset CSS
const GlobalStyle = createGlobalStyle`
  ${reset}
`;

const App: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLiveness, setIsLiveness] = useState(false);
  const [currentStep, setCurrentStep] = useState('SelectIdType');
  const [sessionId, setSessionId] = useState<string | null>(null); // FaceLiveness session ID, not CL or LBID
  const [idType, setIdType] = useState(0);
  const [idImage, setIdImage] = useState<string | null>(null); // Base64 encoded ID image
  const [rekognitionEkycResult, setRekognitionEkycResult] = useState<RekognitionEkycResult>(null);
  const [errorMessage, setErrorMessage] = useState('本人確認中に問題が発生しました。時間をおいて再度お試しください。');

  // API call to create the Face Liveness Session
  useEffect(() => {
    const fetchCreateLiveness = async () => {
      try {
        const response = await fetch(FACE_LIVENESS_SESSION_ENDPOINT, {method: 'POST'});
        if (!response.ok) throw new Error('サーバーとの通信に失敗しました。');

        const data = await response.json();
        setSessionId(data.sessionId);
      } catch (error) {
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          setErrorMessage('サーバーがタイムアウトしました。もう一度お試しください。');
        } else {
          setErrorMessage(error.message || '不明なエラーが発生しました。');
        }
        setCurrentStep('ErrorPage');
      } finally {
        setIsLoading(false);
      }
    };
    fetchCreateLiveness();
  }, []);

  useEffect(() => {
    if (currentStep === 'FaceLiveness') {
      setIsLiveness(true);
    } else {
      setIsLiveness(false);
    }
  }, [currentStep]);

  // Switch components based on currentStep
  let content = <Loader>Loading...</Loader>;
  if (!isLoading) {
    switch (currentStep)  {
      case 'SelectIdType':
        content = <SelectIdType idType={idType} setIdType={setIdType} setCurrentStep={setCurrentStep} setErrorMessage={setErrorMessage} />;
        break;
      case 'UploadIdImage':
        content = <UploadIdImage sessionId={sessionId} idType={idType} setIdImage={setIdImage} setCurrentStep={setCurrentStep} setErrorMessage={setErrorMessage} />;
        break;
      case 'ReferenceIdImage':
        content = <ReferenceIdImage idType={idType} idImage={idImage} setIdImage={setIdImage} setCurrentStep={setCurrentStep} />;
        break;
      case 'FaceLiveness':
        content = <StartFaceLiveness sessionId={sessionId} setCurrentStep={setCurrentStep} setErrorMessage={setErrorMessage} />;
        break;
      case 'ProcessEkyc':
        content = <ProcessEkyc sessionId={sessionId} setRekognitionEkycResult={setRekognitionEkycResult} setCurrentStep={setCurrentStep} setErrorMessage={setErrorMessage} />;
        break;
      case 'CompleteEkyc':
        content = <CompleteEkyc rekognitionEkycResult={rekognitionEkycResult} />;
        break;
      default:
        content = <ErrorPage sessionId={sessionId} errorMessage={errorMessage} />;
    }
  }

  return (
    <>
      <GlobalStyle />
      <Layout sessionId={sessionId} isLiveness={isLiveness}>
        {content}
      </Layout>
    </>
  );
}

export default App;
