import { memo, FC, Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
};

export const Header: FC<Props> = memo(({ setIsModalOpen }) => {
  const handleClose = () => setIsModalOpen(true);

  return (
    <SHeader>
      <STitleContainer>
        <STitleText>本人確認</STitleText>
      </STitleContainer>
      <SCloseButton onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark} size="lg" />
      </SCloseButton>
    </SHeader>
  );
});

const SHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 190 126);
  height: 60px;
  color: white;
  padding: 0.75rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1.25rem;
  }
`;

const STitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const STitleText = styled.h2`
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`;

const SCloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 1rem;
`;
