export interface RekognitionEkycResult {
  sessionId: string,
  status: 'completed' | 'aborted' | 'timeout' | 'error',
};

export const sendRekognitionEkycResult = (result: RekognitionEkycResult) => {
  window.parent.postMessage({
    type: 'rekognitionEkycResult',
    data: result,
  }, '*');
};
